import { take, call, put, race, select, takeLatest, cancel, cancelled, fork } from 'redux-saga/effects'
import { eventChannel, END } from 'redux-saga'
import * as lineupActions from '../actions/lineup'
import * as lineupSelectors from '../reducers/lineup'
import * as groupSelectors from '../reducers/groups'
import * as afterStartSelectors from '../reducers/after-start'
import { reorderRawNFLRuns, reorderNBARuns } from '../utils/reorder-runs'
import { partnerURI } from '../constants/api'
import {
  sanitizePlayerTable,
  sanitizeGeneralSettings,
  sanitizePlayerGroups
 } from '../utils/sanitize-data'

 import { getAdvancedRowsFromBasic } from '../utils/specific-stacks'
 import { getToken } from '../utils/tokens'
 import { DEFAULT_COMPANY } from './lineup'

// Times
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import getLockedTeams from '../utils/locked-teams'

dayjs.extend(utc)
dayjs.extend(tz)
dayjs.extend(customParseFormat)


// const socketServerURL = 'wss://staging.dfsforecast.com'
// const socketServerURL = process.env.REACT_APP_ENVIRONMENT === 'development' ? 'ws://localhost:5000/' : 'wss://opt.dfsforecast.com'
let socketServerURL = process.env.REACT_APP_ENVIRONMENT === 'development' ? 'wss://opt.dfsforecast.com' : 'wss://opt.dfsforecast.com'
if (process.env.REACT_APP_OPT_OVERRIDE) {
  socketServerURL = process.env.REACT_APP_OPT_OVERRIDE
}
// const backupServerURL = 'wss://api-2.dfsforecast.com'
// const loadBalancedServer = 'wss://api.dfsforecast.com'

let connected = false

function* fetchOpt(params) {
  // Open new connection every time
  const uris = [socketServerURL]
  const r = 0

  let lus = []
  let _useSaved
  let sanitizedParams

  // don't establish a connection until we are ready to set handlers to avoid a race condition
  let ws

  try {
    // Get data
    const players = yield select(lineupSelectors.getPlayers)
    const teamStacks = yield select(lineupSelectors.getTeamStacks)
    const savedLineups = yield select(lineupSelectors.getSavedLineups)

    // After Start Activated
    const afterStartActivated = yield select(afterStartSelectors.getAfterStartActivated)
    let afterStartLineups = []
    let _afterStartLineups = {...yield select(afterStartSelectors.getAfterStartLineups)}
    // Filter for active slates
    Object.keys(_afterStartLineups).forEach(k => {
      if (_afterStartLineups[k].active) {
        afterStartLineups = afterStartLineups.concat(_afterStartLineups[k].lineups)   
      }
    })

    // Sanitize all data
    const sanitizedPlayers = yield call(sanitizePlayerTable, players)
    sanitizedParams = yield call(sanitizeGeneralSettings, params)

    // if (sanitizedParams.sport === 'nba') {
    //   uris[0] = 'wss://test-opt.dfsforecast.com'
    // }

    // devise specific stacks from settings
    if (sanitizedParams.stackSettingMode === 'basic')
      sanitizedParams.specificStacks = getAdvancedRowsFromBasic(sanitizedParams)
    
    // override number of lineups if afterStart 
    if (afterStartActivated) {
      sanitizedParams.numLUs = afterStartLineups.length

      // if nba pass the locked teams
      if (sanitizedParams.sport === 'nba') {
        const lockedTeams = getLockedTeams({ players: sanitizedPlayers})
        // use teamsLocked instead of lockedTeams so doesn't match server
        sanitizedParams.teamsLocked = lockedTeams
      }
    }

    const lineupLength = params.lineupLength
    _useSaved = sanitizedParams.includeSaved

    // Groups
    const groups = yield select(groupSelectors.getPlayerGroups)
    let sanitizedGroups = yield call(sanitizePlayerGroups, players, groups)
    if (sanitizedGroups.length === 0)
      sanitizedGroups = undefined
    // Auth Token
    // const token = localStorage.getItem(JWT_LOOKUP)
    const token = getToken()
    // Log lineup build -- don't block
    try {
      const uri = `${partnerURI}/${sanitizedParams.sport}/built-lineups/${DEFAULT_COMPANY}/${sanitizedParams.site}/${sanitizedParams.slate}/${sanitizedParams.counter}/${sanitizedParams.season}/${sanitizedParams.numLUs}`
      yield call(fetch, uri, {
        method: 'POST',
        headers: {
          authorization: token,
          'Content-Type': 'application/json'
        }
      })
    } catch(e) {
      // Do nothing on error
    }


    // create a new luRun
    const luRunUri = `${partnerURI}/${sanitizedParams.sport}/lineup-runs/${DEFAULT_COMPANY}/${sanitizedParams.site}/${sanitizedParams.slate}/${sanitizedParams.season}/${sanitizedParams.counter}`
    let luRunReq = yield call(fetch, luRunUri, {
      method: 'POST',
      headers: {
        authorization: token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        settings: sanitizedParams
      })
    })

    const luRunRes = yield call([luRunReq, luRunReq.json])

    if (!luRunRes.success) {
      throw Error("Unable to make new run")
    }

    const luRun = luRunRes.lineup_run
    // add lineup run and select it
    yield put({
      type: lineupActions.opt.SET_LINEUP_RUN_FETCH,
      lineupRunId: luRun.id,
      site: sanitizedParams.site,
      slate: sanitizedParams.slate,
      sport: sanitizedParams.sport,
      counter: sanitizedParams.counter,
      season: sanitizedParams.season
    })

    // Set up promise to wait on result of websocket
    let resolver
    let rejector
    const _resultPromise = new Promise((resolve, reject) => {
      resolver = resolve
      rejector = reject
    })


    let _savedLineups = null
    if (_useSaved) {
      _savedLineups = savedLineups.map(sv => {
        // return rawTable to pass to opt
        return sv.rawTable
      }).flat()
    }

    let cancelled = false

    ws = new WebSocket(uris[r] + '')

    ws.onclose = () => {
      connected = false
    }
    ws.onerror = (error) => {
      console.log(error)
      rejector()
    }

    const channel = eventChannel(emitter => {
      // WS connection and send
      ws.onmessage = (e) => {
        emitter({data: e.data})
      }

      return () => {}
    })

    // Set up websocket connection
    ws.onopen = () => {
      connected = true
      ws.send(JSON.stringify({ 
        token, 
        players: sanitizedPlayers, 
        teamStacks, 
        groups: sanitizedGroups, 
        savedLineups: _savedLineups, 
        afterStartActivated,  
        afterStartLineups, 
        ...sanitizedParams 
      }))
    }

    // Channel
    let more = true
    let error = false

    while (more && !error && !cancelled) {
      const { data } = yield take(channel)

      let msg = null
      try {
        msg = JSON.parse(data)
        // if error kick out
        if (msg.error) {
          error = true
          rejector()
        }

        // We have a new LU. Add it to store
        yield put(({
          type: lineupActions.opt.ADD_NEW_LINEUP, 
          lineup: msg.lu,
          lineupNum: msg.luNum,
          runId: luRun.id
        }))

        // Let saga know we are done
        let _curNum = msg.luNum
        if (_useSaved) _curNum += (savedLineups.length)
        if (_curNum === sanitizedParams.numLUs) {
          ws.close()
          more = false
          resolver(true)
        }
      } catch(e) {
        console.error(`Error parsing : ${e.data}`)
        error = true
        rejector()
      }
    }

    try {
      const { _response, _cancel } = yield race({
        _response : _resultPromise,
        _cancel: take(lineupActions.opt.CANCEL)
      })

      if (_cancel) {
        cancelled = true
        ws.close()
        yield put(({type: lineupActions.opt.CANCEL}))
      } else {
        if (_useSaved) {
          switch (params.sport) {
            case 'nfl':
              lus = yield call(reorderRawNFLRuns, lus.concat(_savedLineups))
              break
            case 'nba':
              lus = yield call(reorderNBARuns, lus.concat(_savedLineups))
              break
            default:
              break
          }
        }

        yield put(({
          type: lineupActions.opt.RECEIVE,
          payload: lus,
          site: sanitizedParams.site,
          slate: sanitizedParams.slate
        }))
      }
    } catch (e) {
      console.log(e)
      yield put(({type: lineupActions.opt.FAIL_FETCH}))
    }
  } finally {
    if (yield cancelled()) {
      // close connection if opened. ignore if not
      try {
        ws.close()
      } catch (e) {}
      
      if (_useSaved) {
        switch (params.sport) {
          case 'nfl':
            lus = yield call(reorderRawNFLRuns, lus.concat(_savedLineups))
            break
          case 'nba':
            lus = yield call(reorderNBARuns, lus.concat(_savedLineups))
            break
          default:
            break
        }
      }

      yield put(({
        type: lineupActions.opt.RECEIVE,
        payload: lus,
        site: sanitizedParams.site,
        slate: sanitizedParams.slate
      }))
    }

    yield put(({
      type: lineupActions.opt.RUN_COMPLETE
    }))
  }
}

function* generateLineups({ payload: { params }}) {
  const task = yield fork(fetchOpt, params)
  // wait for cancel action
  yield take(lineupActions.opt.CANCEL)
  // if we get it -- cancel build
  yield cancel(task)
}

export default function* wsSagas() {
  // OPT
  yield takeLatest(
    lineupActions.opt.FETCH,
    generateLineups
  )
}
